import { useState, useEffect, memo } from "react";
import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import { GET_JOIN_PROCESS_PODIAN } from "../../Apis/podian_api";
import { SlideFromLeft } from "../../Utils/Animations";

const Steps = memo(() => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await GET_JOIN_PROCESS_PODIAN();
    if (res && res.s) {
      setData(res.r);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt="5.5rem"
    >
      <Box
        width="98%"
        minHeight="22rem"
        display="flex"
        flexDirection={["column", "column", "column", "row", "row"]}
        gap={["1rem", "1rem", "none", "none", "none"]}
      >
        {data?.map((e, i) => (
          <SlideFromLeft>
            <Stack
              borderRadius="0.4rem"
              bgcolor={[
                "#EDF1F4",
                "#EDF1F4",
                "#EDF1F4",
                "transparent",
                "transparent",
              ]}
              key={i}
              justifyContent="center"
              gap={["0.4rem", "0.4rem", "1.5rem", "1.5rem", "1.5rem"]}
              px={"1.3rem"}
              mx={"1.2rem"}
              borderLeft={[
                "none",
                "none",
                i >= 1 && "1px solid gray",
                i >= 1 && "1px solid gray",
                i >= 1 && "1px solid gray",
              ]}
            >
              <Box
                display="flex"
                flexDirection={["row", "row", "row", "column", "column"]}
                alignItems="center"
                gap={["1rem", "1rem", "1.5rem", "0", "0"]}
              >
                <Typography
                  fontSize={["18px", "19px", "20px", "20px", "20px"]}
                  color={theme.palette.primary.second}
                >
                  {e.sr}.
                </Typography>
                <Typography
                  fontSize={["16px", "18px", "20px", "20px", "20px"]}
                  variant="h1"
                  my="1.4rem"
                >
                  {e.name}
                </Typography>
              </Box>
              <Typography
                variant="little"
                fontSize={["14px", "15px", "17px", "17px", "17px"]}
                pb={["0.4rem", "0.4rem", "0", "0", "0"]}
              >
                {e.details}
              </Typography>
            </Stack>
          </SlideFromLeft>
        ))}
      </Box>
    </Box>
  );
});

export default Steps;

import { memo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import TextFieldView from "../../Components/TextFieldView";
import ButtonView from "../../Components/ButtonView";
import { SlideFromLeft, SlideFromRight } from "../../Utils/Animations";
import { GET_BROCHURE } from "../../Apis/podian_api";
import { ToastContainer, toast } from "react-toastify";
import { brochureSchema } from "../../Schema";
import { useFormik } from "formik";
import { API_URL } from "../../Apis/config";

const initFormValues = {
  firstName: "",
  lastName: "",
  city: "",
  email: "",
  mobile: "",
};

const Brochure = memo(({ value }) => {
  const [mobNum, setMobNum] = useState("");

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: initFormValues,
      validationSchema: brochureSchema,
      onSubmit: (values, action) => {
        handleOnSubmit(values);
        action.resetForm();
      },
    });

  const handleOnSubmit = async (formValues) => {
    const res = await GET_BROCHURE(formValues);
    console.log("res", res);
    if (res && res.s === 1) {
      toast(res.m);
    } else if (res && res.s === 0) {
      toast(res.m);
    } else {
      toast(res.m);
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={["98%", "98%", "95%", "86%", "86%"]}
        height="100%"
        display="flex"
        justifyContent={[
          "center",
          "center",
          "space-between",
          "space-between",
          "space-between",
        ]}
        alignItems="center"
        pt={["3rem", "3rem", "7rem", "7rem", "7rem"]}
        pb={["3rem", "3rem", "0.5rem", "0rem", "0rem"]}
      >
        <Box
          width={["80%", "80vw", "45vw", "45vw", "45vw"]}
          height="60vh"
          display={["none", "none", "block", "block", "block"]}
        >
          <SlideFromLeft style={{
            height:'100%'
          }}>
            <Box sx={{
              height:'100%'
            }}>
              <img
                src={
                  value
                    ? API_URL.baseUrl + API_URL.getMasterImage + value
                    : require("../../Assets/cameraman2.jpg")
                }
                style={{
                  objectFit: "cover",
                  objectPosition:'10% 34%',
                  width: "100%",
                  height: "100%",
                  borderRadius: "25px",
                }}
                alt=""
              />
            </Box>
          </SlideFromLeft>
        </Box>

        <Box
          width={["80vw", "80vw", "45vw", "45vw", "45vw"]}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="3rem"
        >
          <SlideFromRight>
            <Stack alignItems="center">
              <Typography
                variant="h3"
                fontSize={["27px", "38px", "46px", "54px", "54px"]}
                textAlign="center"
              >
                To get the brochure
              </Typography>
              <Typography
                variant="little"
                fontSize={["15px", "16px", "18px", "18px", "18px"]}
                textAlign="center"
              >
                fill out the following information
              </Typography>
            </Stack>
          </SlideFromRight>

          <SlideFromRight
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.2rem",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Stack width="100%" height="100%" alignItems="center" gap="2rem">
                <Box
                  display="flex"
                  gap="1rem"
                  width={["100%", "100%", "95%", "80%", "80%"]}
                >
                  <Stack width="50%">
                    <TextFieldView
                      label="First Name"
                      width="100%"
                      bgcolor="white"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.firstName && touched.firstName ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: ["14px", "14px", "16px", "16px", "16px"],
                        }}
                      >
                        {errors.firstName}
                      </Typography>
                    ) : null}
                  </Stack>
                  <Stack width="50%">
                    <TextFieldView
                      label="Last Name"
                      width="100%"
                      bgcolor="white"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.lastName && touched.lastName ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: ["14px", "14px", "16px", "16px", "16px"],
                        }}
                      >
                        {errors.lastName}
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="1rem"
                  width={["100%", "100%", "95%", "80%", "80%"]}
                >
                  <Stack>
                    <TextFieldView
                      label="City"
                      width="100%"
                      bgcolor="white"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.city && touched.city ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: ["14px", "14px", "16px", "16px", "16px"],
                        }}
                      >
                        {errors.city}
                      </Typography>
                    ) : null}
                  </Stack>
                  <>
                    <TextFieldView
                      label="Email"
                      width="100%"
                      bgcolor="white"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: ["14px", "14px", "16px", "16px", "16px"],
                        }}
                      >
                        {errors.email}
                      </Typography>
                    ) : null}
                  </>
                  <>
                    <TextFieldView
                      label="Phone Number"
                      width="100%"
                      bgcolor="white"
                      name="mobile"
                      value={values.mobile}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setMobNum(e.target.value);
                          handleChange(e);
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.mobile && touched.mobile ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: ["14px", "14px", "16px", "16px", "16px"],
                        }}
                      >
                        {errors.mobile}
                      </Typography>
                    ) : null}
                  </>
                </Box>
              </Stack>
              <Box
                sx={{
                  marginLeft: ["0", "0", "0", "4rem", "4rem"],
                  mt: "1.5rem",
                  display: ["flex", "flex", "flex", "block", "block"],
                  justifyContent: "center",
                }}
              >
                <ButtonView label="Get Brochure" onClick={handleSubmit} />
              </Box>
            </form>
          </SlideFromRight>
        </Box>
      </Box>

      <ToastContainer />
    </Box>
  );
});

export default Brochure;

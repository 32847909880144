import { useState, useEffect, useRef, memo } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import "swiper/swiper-bundle.css";
import Swiper from "swiper";
import swipe_prev from "../../Assets/swipe_prev.png";
import swipe_next from "../../Assets/swipe_next.png";
import { SlideFromRight } from "../../Utils/Animations";
import { API_URL } from "../../Apis/config";
import { GET_ANNOUNCEMENTS_LIST } from "../../Apis/home_api";
import AnnouncementModal from "../../Components/Modal/AnnouncementModal";

const Announcements = memo(() => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await GET_ANNOUNCEMENTS_LIST();
    if (res && res.s) {
      setData(res.r ?? []);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //Slider
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current = new Swiper(".mySwiper", {
      slidesPerView: "1.2",
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    return () => {
      swiperRef.current.destroy();
    };
  }, []);

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  //Slider

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#FDE89D",
        py: ["1rem", "1rem", "3.3rem", "3.3rem", "3.3rem"],
        display: !data.length && "none",
      }}
    >
      <SlideFromRight>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: ["column", "column", "column", "row", "row"],
          }}
        >
          <Typography
            sx={{
              display: [
                "inline-block",
                "inline-block",
                "inline-block",
                "none",
                "none",
              ],
              fontSize: ["25px", "26px", "42px", "0", "0"],
              fontFamily: "Sora",
              textAlign: "center",
            }}
          >
            Explore More Announcements
          </Typography>
          <Box
            display={["none", "none", "none", "flex", "flex"]}
            sx={{
              width: "40%",
              pl: "0.8rem",
              paddingRight: "0.2rem",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "58px",
                fontFamily: "Sora",
                lineHeight: "73px",
              }}
            >
              Explore More Announcements
            </p>

            <div
              style={{
                width: "100%",
              }}
            >
              <IconButton onClick={handlePrevClick}>
                <img src={swipe_prev} style={{ width: "3rem" }} alt="" />
              </IconButton>
              <IconButton onClick={handleNextClick}>
                <img src={swipe_next} style={{ width: "3rem" }} alt="" />
              </IconButton>
            </div>
          </Box>

          <Box
            className="swiper-container mySwiper"
            sx={{
              width: ["100%", "98%", "98%", "60%", "60%"],
              overflow: "clip",
              pl: ["0.7rem", "0.7rem", "0rem", "0rem", "0rem"],
            }}
          >
            <div className="swiper-wrapper">
              {data?.map((e, i) => (
                <Box
                  className="swiper-slide"
                  key={i}
                  sx={{
                    width: ["100%", "100%", "533px", "533px", "533px"],
                    height: ["350px", "370px", "540px", "540px", "540px"],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={
                      API_URL.baseUrl + API_URL.announcementImage + e.photo_1
                    }
                    alt=""
                    style={{
                      width: "100%",
                      height: "55%",
                      objectFit: "cover",
                      borderTopLeftRadius: "30.12px",
                      borderTopRightRadius: "30.12px",
                      cursor: "grab",
                    }}
                  />
                  <Description name={e.name} description={e.description} />
                </Box>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </Box>
        </Box>
      </SlideFromRight>
    </Box>
  );
});

export default Announcements;

const expanded = false;

const Description = ({ name, description }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        height: "40%",
        width: "100%",
        display: !expanded && "flex",
        flexDirection: !expanded && "column",
        justifyContent: !expanded && "center",
        backgroundColor: "white",
        paddingLeft: "2rem",
        borderBottomLeftRadius: "30.12px",
        borderBottomRightRadius: "30.12px",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize={["18px", "20px", "40px", "40px", "40px"]}
        fontWeight="500"
      >
        {name}
      </Typography>
      <Typography
        color="rgba(0, 0, 0, 0.70)"
        fontSize={["15px", "18px", "26px", "26px", "26px"]}
        noWrap={!expanded}
      >
        {description}
      </Typography>
      {!expanded &&
      description !== "" &&
      description !== null &&
      description.length >= 30 ? (
        <Typography
          variant="little"
          color="main"
          style={{ cursor: "pointer" }}
          fontSize={["15px", "16px", "18px", "18px", "18px"]}
          onClick={() => {
            setOpen(true);
          }}
        >
          ... Read more
        </Typography>
      ) : null}

      {open ? (
        <AnnouncementModal
          open={open}
          description={description}
          handleClose={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
};

import { Box } from "@mui/material";
const Card = ({ image, link = false }) => {
  return (
    <>
      <Box
        width="100%"
        display={[
          "inline-block",
          "inline-block",
          "inline-block",
          "none",
          "none",
        ]}
      >
        {!link ? (
          <div
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              loading="lazy"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
              alt=""
            />
          </div>
        ) : (
          <div
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              loading="lazy"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
              alt=""
              onClick={() => {
                window.open(link);
              }}
            />
          </div>
        )}
      </Box>

      <Box
        width="100%"
        display={["none", "none", "none", "inline-block", "inline-block"]}
      >
        {!link ? (
          <img
            src={image}
            loading="lazy"
            style={{
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
            }}
            alt=""
          />
        ) : (
          <img
            src={image}
            loading="lazy"
            style={{
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
            }}
            alt=""
            onClick={() => {
              window.open(link);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default Card;

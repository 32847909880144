import { memo } from "react";
import announcement_2 from "../../Assets/announcement_2.jpg";
import announcement_3 from "../../Assets/announcement_3.jpg";
import announcement_4 from "../../Assets/announcement_4.jpg";
import announcement_1 from "../../Assets/announcement_1.jpg";
import { Box } from "@mui/material";
import YourComponent from "./Slider/YourComponent";
import { SlideFromRight } from "../../Utils/Animations";

const arr = [announcement_1, announcement_2, announcement_3, announcement_4];

const BookNowBanner = memo(() => {
  return (
    <Box sx={{ width: "100vw" }}>
      <SlideFromRight sx={{ width: "100%" }}>
        <YourComponent data={arr} />
      </SlideFromRight>
    </Box>
  );
});

export default BookNowBanner;

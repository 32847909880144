import { Menu, MenuItem, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SubMenuLegal = ({ openMenu, setOpenMenu, onClick }) => {
  return (
    <div>
      <Typography
        variant="footerlink"
        fontSize={["18px", "18px", "16px", "18px", "18px"]}
        onClick={onClick}
        sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        Legal <KeyboardArrowDownIcon />
      </Typography>

      <Menu
        anchorEl={openMenu}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        style={{ marginTop: "0.5rem" }}
      >
        <NavLink to="/termsofservice" style={{ textDecoration: "none" }}>
          <MenuItem
            style={{ fontSize: "14px" }}
            onClick={() => setOpenMenu(false)}
          >
            <Typography sx={{ fontSize: "1rem", color: "black" }}>
              Terms & Conditions
            </Typography>
          </MenuItem>
        </NavLink>

        <NavLink to="/privacypolicy" style={{ textDecoration: "none" }}>
          <MenuItem
            style={{ fontSize: "14px" }}
            onClick={() => setOpenMenu(false)}
          >
            <Typography sx={{ fontSize: "1rem", color: "black" }}>
              Privacy policy
            </Typography>
          </MenuItem>
        </NavLink>

        <NavLink to="/refundpolicy" style={{ textDecoration: "none" }}>
          <MenuItem
            style={{ fontSize: "14px" }}
            onClick={() => setOpenMenu(false)}
          >
            <Typography sx={{ fontSize: "1rem", color: "black" }}>
              Refund policy
            </Typography>
          </MenuItem>
        </NavLink>
      </Menu>
    </div>
  );
};

export default SubMenuLegal;

import { Avatar, Box, IconButton, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API_URL } from "../../Apis/config";
import EastIcon from "@mui/icons-material/East";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 25,
  bgcolor: "white",
  userSelect: "none",
};

export default function FeatureModal({ open, data, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        backgroundColor: "rgb(20, 0, 0, 0.3)",
        backdropFilter: "blur(4px)",
      }}
    >
      <Box
        width={["90%", "87%", "50rem", "50rem", "50rem"]}
        height={["90%", "87%", "auto", "auto", "auto"]}
        borderRadius="16px"
        p={["1.2rem 0.8rem", "1.2rem 0.8rem", "1.6rem", "1.6rem", "1.6rem"]}
        display="flex"
        flexDirection="column"
        justifyContent={[
          "space-between",
          "space-between",
          "center",
          "center",
          "center",
        ]}
        alignItems="center"
        gap="1rem"
        sx={style}
        overflow="auto"
      >
        <Box
          width="100%"
          display="flex"
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
            "space-between",
          ]}
          alignItems="center"
          flexDirection={["column", "column", "row", "row", "row"]}
        >
          <IconButton
            sx={{
              display: ["inline-block", "inline-block", "none", "none", "none"],
              alignSelf: "flex-end",
              color: "black",
              padding: "3px",
            }}
            onClick={handleClose}
          >
            <CancelIcon style={{ fontSize: "2.3rem" }} />
          </IconButton>
          <Avatar
            src={API_URL.baseUrl + API_URL.featureLogo + data.logo}
            sx={{
              width: ["100%", "100%", "6rem", "6rem", "6rem"],
              height: ["13rem", "14rem", "6rem", "6rem", "6rem"],
              borderRadius: ["0.7rem", "0.7rem", "100%", "100%", "100%"],
              objectFit: "center",
            }}
            alt=""
          />
          <IconButton
            sx={{
              display: [
                "none",
                "none",
                "inline-block",
                "inline-block",
                "inline-block",
              ],
              alignSelf: "flex-start",
              color: "black",
              padding: "3px",
            }}
            onClick={handleClose}
          >
            <CancelIcon style={{ fontSize: "2rem" }} />
          </IconButton>
        </Box>

        <Box
          flex="1"
          px="1rem"
          borderRadius="8px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontSize="1.8rem" fontWeight="600">
            {data.name ?? ""}
          </Typography>

          <Typography mt="0.8rem">{data.description ?? ""}</Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton
            onClick={() => {
              const url = data.url.startsWith("http")
                ? data.url
                : `http://${data.url}`;
              window.open(url, "_blank");
            }}
            style={{ borderRadius: "2rem" }}
          >
            <Typography mr="1rem">OPEN LINK</Typography>
            <EastIcon />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
}

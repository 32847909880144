import { API_URL } from "../../../Apis/config";
import styles from "../../../Modules/Home.module.css";

function convertShootValue(shoot) {
  if (shoot >= 1000 && shoot < 1000000) {
    const result = shoot / 1000;
    return result % 1 === 0 ? `${result.toFixed(0)}k` : `${result.toFixed(1)}k`;
  } else if (shoot >= 1000000 && shoot < 1000000000) {
    const result = shoot / 1000000;
    return result % 1 === 0 ? `${result.toFixed(0)}m` : `${result.toFixed(1)}m`;
  } else if (shoot >= 1000000000 && shoot < 1000000000000) {
    const result = shoot / 1000000000;
    return result % 1 === 0 ? `${result.toFixed(0)}b` : `${result.toFixed(1)}b`;
  } else if (shoot >= 1000000000000 && shoot < 1000000000000000) {
    const result = shoot / 1000000000000;
    return result % 1 === 0 ? `${result.toFixed(0)}t` : `${result.toFixed(1)}t`;
  } else if (shoot >= 1000000000000000 && shoot < 1000000000000000000) {
    const result = shoot / 1000000000000;
    return result % 1 === 0 ? `${result.toFixed(0)}q` : `${result.toFixed(1)}q`;
  } else if (shoot >= 1000000000000000000) {
    const result = shoot / 1000000000000000000;
    return result % 1 === 0 ? `${result.toFixed(0)}Q` : `${result.toFixed(1)}Q`;
  } else {
    return shoot.toString();
  }
}

const Card = ({ photo, name, shoots }) => {
  return (
    <div
      className={styles.cities_carousel}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.3)), url(${
          API_URL.baseUrl + API_URL.cityImage + photo
        })`,
      }}
    >
      <div className={styles.carousel_div_text}>
        <p>{name}</p>
        <p>{convertShootValue(shoots)}+ Shoots</p>
      </div>
    </div>
  );
};

export default Card;

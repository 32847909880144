import { Box, IconButton, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 25,
  bgcolor: "white",
  userSelect: "none",
};

export default function AnnouncementModal({ open, description, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        backgroundColor: "rgb(20, 0, 0, 0.3)",
        backdropFilter: "blur(4px)",
      }}
    >
      <Box
        borderRadius="16px"
        p={["0.6rem", "0.6rem", "1.2rem", "1.2rem", "1.2rem"]}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={style}
        maxHeight={["90vh", "90vh", "auto", "auto", "auto"]}
        width={["90vw", "90vw", "auto", "auto", "auto"]}
      >
        <Box
          py="0.8rem"
          px={["0rem", "1rem", "1rem", "1rem", "1rem"]}
          borderRadius="8px"
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="little"
            fontSize={["14px", "14px", "18px", "18px", "18px"]}
          >
            {description ?? ""}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton
            style={{
              alignSelf: "flex-start",
              color: "black",
              padding: "0",
            }}
            onClick={handleClose}
          >
            <CancelIcon style={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
}

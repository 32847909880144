import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
// import { LeftArrow, RightArrow } from "./Arrows";
import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import "./hideScrollbar.css";
import Card from "./Card";
import { PLAY_STORE_USER } from "../../../Utils/links";

export default function YourComponent({ data }) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollPrev();
    } else if (ev.deltaY > 0) {
      apiObj.scrollNext();
    }
  };

  return (
    <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
      <ScrollMenu onWheel={onWheel} transitionBehavior="smooth">
        {data?.map((e, i) => (
          <Card key={i} photo={e.photo} name={e.name} shoots={e.shoots} />
        ))}
      </ScrollMenu>
    </div>
  );
}

export const PLAY_STORE_USER =
  "https://play.google.com/store/apps/details?id=com.seawindsolution.pod";

export const PLAY_STORE_PODIAN =
  "https://play.google.com/store/apps/details?id=com.pod.podian";

export const APP_STORE_USER =
  "https://apps.apple.com/in/app/photographer-on-demand/id1503321883";

export const APP_STORE_PODIAN =
  "https://apps.apple.com/in/app/podian/id1502690681";

export const LINKEDIN_LINK = "https://www.linkedin.com/company/podian/";

export const INSTAGRAM_LINK = "https://www.instagram.com/podbharat/";

export const YOUTUBE_LINK =
  "https://www.youtube.com/@podphotographerondemand9334";

export const FACEBOOK_LINK =
  "https://www.facebook.com/podphotographerondemand/";

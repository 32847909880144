import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

// import { LeftArrow, RightArrow } from "./Arrows";

import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import "./hideScrollbar.css";
import Card from "./Card";

// const Arrows = () => (
//   <div
//     style={{
//       width: "100%",
//       display: "flex",
//       justifyContent: "center",
//     }}
//   >
//     Some other content
//     <div style={{ marginLeft: "10px", display: "flex" }}>
//       <LeftArrow /> <RightArrow />
//     </div>
//   </div>
// );

export default function YourComponent({ data }) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollPrev();
    } else if (ev.deltaY > 0) {
      apiObj.scrollNext();
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
          <ScrollMenu
            onWheel={onWheel}
            transitionBehavior="smooth"
            scrollContainerClassName="scrollContainer"
          >
            {data?.map((e) => (
              <Card
                name={e.name}
                desc={e.description}
                key={e.id}
                logo={e.logo}
              />
            ))}
          </ScrollMenu>
        </div>
      </div>
    </>
  );
}

import { useState } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FeatureModal from "../Modal/FeatureModal";

const SubMenuFeatures = ({ openMenu, setOpenMenu, onClick, data }) => {
  const [open, setOpen] = useState(false);
  const [featureData, setFeatureData] = useState({});

  return (
    <div>
      <Typography
        variant="footerlink"
        fontSize={["18px", "18px", "16px", "18px", "18px"]}
        onClick={onClick}
        sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        Features <KeyboardArrowDownIcon />
      </Typography>

      <Menu
        anchorEl={openMenu}
        open={openMenu}
        onClose={() => {
          setOpenMenu(null);
        }}
        style={{ marginTop: "0.5rem" }}
      >
        {data?.map((e, i) => (
          <MenuItem
            key={i}
            style={{ fontSize: "14px" }}
            onClick={() => {
              setFeatureData(e);
              setOpenMenu(null);
              setOpen(true);
            }}
          >
            <Typography sx={{ fontSize: "1rem", color: "black" }}>
              {e.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      {open ? (
        <FeatureModal
          open={open}
          data={featureData}
          handleClose={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
};

export default SubMenuFeatures;

import { API_URL, GETAPI, POSTAPI } from "./config";

//MASTER
export const GET_MASTER_LIST = async () => {
  const res = await GETAPI(API_URL.getMasterList);
  return res;
};

//ANNOUNCEMENTS
export const GET_ANNOUNCEMENTS_LIST = async () => {
  const res = await GETAPI(API_URL.getAnnouncements);
  return res;
};

export const GET_ANNOUNCEMENT_DETAILS = async (id) => {
  let query = "";
  query += "&id=" + id;

  const res = await GETAPI(API_URL.getAnnouncementById, query);
  return res;
};

//CITIES
export const GET_CITIES_LIST = async () => {
  const res = await GETAPI(API_URL.getCities);
  return res;
};

export const GET_CITY_DETAILS = async (id) => {
  let query = "";
  query += "&id=" + id;

  const res = await GETAPI(API_URL.getCityById, query);
  return res;
};

//CLIENT REVIEWS
export const GET_REVIEWS = async () => {
  const res = await GETAPI(API_URL.getReviews);
  return res;
};

//BRANDS
export const GET_BRANDS_LIST = async () => {
  const res = await GETAPI(API_URL.getBrands);
  return res;
};

export const GET_BRAND_DETAILS = async (id) => {
  let query = "";
  query += "&id=" + id;

  const res = await GETAPI(API_URL.getBrandById, query);
  return res;
};

//GET STATISTICS
export const GET_STATISTICS = async () => {
  const res = await GETAPI(API_URL.getStatistics);
  return res;
};

//SUBSCRIBE TO BLOGS
export const SUBSCRIBE_TO_BLOGS = async (payload) => {
  const formData = new FormData();
  formData.append("email", payload.email);

  const res = await POSTAPI(API_URL.subscribeBlogs, formData);
  return res;
};

//PORTFOLIO
export const GET_PORTFOLIO = async () => {
  const res = await GETAPI(API_URL.getPortfolio);
  return res;
};

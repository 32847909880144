import { useState, useEffect } from "react";
import { Box, Stack, CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GET_FAQ_LIST } from "../../Apis/FAQ_api";

const FAQ = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const res = await GET_FAQ_LIST(0);
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt="3rem"
        >
          <Stack width="86%" gap="1.8rem">
            <Typography variant="h3" alignSelf="center">
              FAQ's
            </Typography>

            {data?.map((e, i) => (
              <div key={i}>
                <Accordion
                  sx={{
                    bgcolor: "#F1F1F1",
                    border: "0",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontSize="18px" fontWeight="500">
                      {e.question}
                    </Typography>
                  </AccordionSummary>
                  <div
                    dangerouslySetInnerHTML={{ __html: e.answer }}
                    style={{
                      width: "97%",
                      margin: "auto",
                      paddingBottom: "1rem",
                    }}
                  />
                </Accordion>
              </div>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default FAQ;

import { Outlet, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../Pages/Layout/Header";
import Footer from "../Pages/Layout/Footer";

function ProtectedRoute() {
  const navigate = useNavigate();

  const isAuthenticated = true; //token

  if (!isAuthenticated) {
    navigate("/");
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
    >
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </Box>
  );
}

export default ProtectedRoute;

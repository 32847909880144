import { useState, useEffect, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../../Modules/Home.module.css";
import { Box, Typography } from "@mui/material";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Autoplay from "./../../../node_modules/swiper/modules/autoplay.mjs";

import { SlideFromLeft, TextAnimationFade } from "../../Utils/Animations";
import { GET_CITIES_LIST } from "../../Apis/home_api";
import { API_URL } from "../../Apis/config";
import YourComponent from "./CitySlider/YourComponent";

const Cities = memo(({ dataM = [] }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await GET_CITIES_LIST();
    if (res && res.s) {
      setData(res.r);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="cities" style={{ width: "100%", marginTop: "1.6rem" }}>
      <SlideFromLeft>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pb: "2.4rem",
          }}
        >
          <TextAnimationFade
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: ["20px", "20px", "38px", "44px", "44px"],
                lineHeight: "50px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {dataM ? dataM[0]?.value : "We're Popping Up Everywhere!"}
            </Typography>
            <Typography
              variant="little"
              fontSize={["11px", "13px", "18px", "18px", "18px"]}
              textAlign="center"
              px="0.7rem"
            >
              {dataM
                ? dataM[1]?.value
                : "Your City, Your Moments - Discover Where POD's Lens Is Focused!"}
            </Typography>
          </TextAnimationFade>
        </Box>

        <YourComponent data={data} />
      </SlideFromLeft>
    </div>
  );
});

export default Cities;

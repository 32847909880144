import * as Yup from "yup";

export const contactUsSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name!"),
  email: Yup.string().email().required("Please enter your email!"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid mobile number!")
    .required("Please enter your mobile number!"),
  address: Yup.string()
    .trim()
    .required("Please enter your address!")
    .test(
      "no-only-spaces",
      "Field should not consist only of spaces!",
      function (value) {
        return value && /\S/.test(value);
      }
    ),
});

export const brochureSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(15)
    .required("Please enter your first name!"),
  lastName: Yup.string()
    .min(2)
    .max(15)
    .required("Please enter your last name!"),
  city: Yup.string().min(2).max(18).required("Please enter your city name!"),
  email: Yup.string().email().required("Please enter your email!"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid mobile number")
    .required("Please enter your mobile number!"),
});

import { Box, IconButton, Modal, Typography } from "@mui/material";
import theme from "../../Utils/Theme";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 25,
  bgcolor: "white",
};

const PodianModal = ({ open, review, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        backgroundColor: "rgb(20, 0, 0, 0.3)",
        backdropFilter: "blur(4px)",
      }}
    >
      <Box
        borderRadius="16px"
        p="2rem"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={style}
      >
        <Typography variant="h4" color={theme.palette.primary.second}>
          Experience of PODIAN
        </Typography>

        {review ? (
          <Typography textAlign="center">{review}</Typography>
        ) : (
          <Typography textAlign="center">
            Thrilled to be named of the month at podian co.! capturing moment
            with passion and creativity makes every snapshot unforgettable.
            #Podaiansuccess
          </Typography>
        )}

        <IconButton
          style={{
            alignSelf: "flex-end",
            color: "black",
            padding: "3px",
          }}
          onClick={handleClose}
        >
          <CancelIcon style={{ fontSize: "2rem" }} />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default PodianModal;

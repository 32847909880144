import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Typography, Stack, IconButton } from "@mui/material";
import { SlideFromLeft } from "../../Utils/Animations";
import add from "../../Assets/Icons/add.png";
import PodianModal from "../../Components/Modal/PodianModal";
import { GET_PODIAN_OF_MONTH } from "../../Apis/podian_api";

const PodianOfMonth = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});

  const getData = async () => {
    const res = await GET_PODIAN_OF_MONTH();
    if (res && res.s) {
      setData(res.r);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <>
      {data ? (
        <Box
          width="100%"
          bgcolor={theme.palette.primary.main}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={["1rem", "1rem", "3rem", "3rem", "3rem"]}
        >
          <SlideFromLeft>
            <Box
              width="100%"
              bgcolor="white"
              display="flex"
              flexDirection={["column", "column", "row", "row", "row"]}
              justifyContent={["center", "center", "none", "none", "none"]}
              borderRadius="12px"
              p={["1.2rem", "1.2rem", "2rem", "2rem", "2rem"]}
              gap={["1rem", "1rem", "0rem", "0rem", "0rem"]}
            >
              <Box
                sx={{
                  width: ["100%", "100%", "45%", "35%", "35%"],
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box width={["60%", "50%", "70%", "70%", "70%"]}>
                  <img
                    src={require("../../Assets/podian_page.png")}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
                <Stack alignItems="center">
                  <Typography
                    variant="h1"
                    fontSize={["21px", "22px", "22px", "22px", "22px"]}
                  >
                    {data?.podian}
                  </Typography>
                  <Typography color={theme.palette.primary.second}>
                    (
                    {data?.roles?.map((e, i) => (
                      <Typography
                        key={i}
                        display="inline-block"
                        fontSize={["12px", "12px", "15px", "15px", "15px"]}
                      >
                        {data.roles.length > 1 && i !== data.roles.length - 1
                          ? `${e.name.trim()},\u00A0`
                          : e.name.trim()}
                      </Typography>
                    ))}
                    )
                  </Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  width: ["100%", "100%", "55%", "65%", "65%"],
                  px: ["0rem", "0rem", "1.4rem", "1.4rem", "1.4rem"],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: ["2rem", "2rem", "0rem", "0rem", "0rem"],
                }}
              >
                <Stack>
                  <Typography
                    width="100%"
                    display={["none", "none", "flex", "flex", "flex"]}
                    flexWrap="wrap"
                    variant="h3"
                    fontSize={["24px", "25px", "47px", "54px", "54px"]}
                    justifyContent="center"
                    gap="0.3rem"
                  >
                    Best{" "}
                    <span
                      style={{
                        backgroundColor: "#F8AF41",
                        padding: "0 8px",
                      }}
                    >
                      PODIAN
                    </span>{" "}
                    Of The Month
                  </Typography>

                  <Typography
                    width="100%"
                    display={["flex", "flex", "none", "none", "none"]}
                    bgcolor="#F8AF41"
                    flexWrap="wrap"
                    variant="h3"
                    fontSize={["20px", "22px", "22px", "22px", "22px"]}
                    py="0.3rem"
                    borderRadius="0.6rem"
                    justifyContent="center"
                    gap="0.3rem"
                  >
                    Best PODIAN Of The Month
                  </Typography>

                  <Typography
                    fontFamily={theme.typography.dances}
                    fontSize={["1.6rem", "1.6rem", "4rem", "6rem", "6rem"]}
                    color={theme.palette.primary.second}
                    display="flex"
                    flexWrap="wrap"
                  >
                    Congratulations
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="little"
                    color="black"
                    fontSize={["14px", "15px", "18px", "18px", "18px"]}
                  >
                    Month: {formatDate(data?.month)}
                  </Typography>

                  <Typography
                    variant="little"
                    color="black"
                    fontSize={["14px", "15px", "18px", "18px", "18px"]}
                  >
                    {data?.score > 0 && `Score: ${data.score}`}
                  </Typography>
                </Stack>

                <Stack display={["flex", "flex", "none", "none", "none"]}>
                  {data?.review ? (
                    <>
                      <Typography fontWeight="600">Review:</Typography>

                      <Typography
                        fontSize={["14px", "15px", "18px", "18px", "18px"]}
                      >
                        {data.review}.
                      </Typography>
                    </>
                  ) : null}
                </Stack>

                <Box
                  width="100%"
                  display={["none", "none", "flex", "flex", "flex"]}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    sx={{
                      justifyContent: "flex-end",
                      padding: "0",
                      borderRadius: "0",
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    <img src={add} style={{ width: "2.6rem" }} alt="" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </SlideFromLeft>

          {openModal && (
            <PodianModal
              review={data?.review ?? ""}
              open={openModal}
              handleClose={() => setOpenModal(false)}
            />
          )}
        </Box>
      ) : null}
    </>
  );
};

export default PodianOfMonth;

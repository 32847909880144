import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBhFHd1hoHhh64-q74CJophVibJza2Q3LM",
  authDomain: "pod-equity.firebaseapp.com",
  projectId: "pod-equity",
  storageBucket: "pod-equity.appspot.com",
  messagingSenderId: "674118162952",
  appId: "1:674118162952:web:1529ab6cd13b6bc4bb8fbd",
  measurementId: "G-CFH3D2YJHC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const ANALYTICS = getAnalytics(app);
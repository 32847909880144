import { Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Home from "../Pages/Home/Home";
import About from "../Pages/About/About";
import Blogs from "../Pages/Blogs/Blogs";
import TermsService from "../Pages/Legal/TermsService";
import FAQ from "../Pages/FAQ/FAQ";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Gallery from "../Pages/Gallery/Gallery";
import Podian from "../Pages/Podian/Podian";
import PrivacyPolicy from "../Pages/Legal/PrivacyPolicy";
import RefundPolicy from "../Pages/Legal/RefundPolicy";
import PodianAbout from "../Pages/Podian/PodianAbout";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import {ANALYTICS} from '../Apis/firebase';
function AllRoutes() {
  const location = useLocation();
  useEffect(() => {
    logEvent(ANALYTICS, 'web_page_view', {
      page_path: location.pathname,
    });
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route index element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/gallery" element={<Gallery />} />

        {/*Discover childs*/}
        <Route path="/podian" element={<Podian />} />
        <Route path="/podian/about" element={<PodianAbout />} />
        {/*Legal childs*/}
        <Route path="/termsofservice" element={<TermsService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
      </Route>
    </Routes>
  );
}

export default AllRoutes;

import { memo, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { GET_BRANDS_LIST } from "../../Apis/home_api";
import { API_URL } from "../../Apis/config";

const Companies = memo(({ dataM = [] }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await GET_BRANDS_LIST();
    if (res && res.s) {
      setData(res.r);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "black",
        width: "100%",
        padding: ["1.6rem 0", "1.9rem 0", "4rem 0", "4rem 0", "4rem 0"],
        mt: "2.9rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3rem",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontWeight: "600",
            fontSize: ["20px", "20px", "38px", "44px", "44px"],
            lineHeight: "50px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {dataM ? dataM[0]?.value : "POD's Star-Studded Ensemble"}
        </Typography>
        <Typography
          variant="transparent"
          fontWeight="500"
          fontSize={["11px", "13px", "18px", "18px", "18px"]}
          textAlign="center"
          px="0.7rem"
        >
          {dataM
            ? dataM[1]?.value
            : "When Brands and POD Get Together, Magic Unfolds!"}
        </Typography>
      </Box>

      <Marquee
        speed={85}
        loop={0}
        autoFill={true}
        style={{ width: "100%", overflow: "hidden", paddingBottom: "2.1rem" }}
      >
        {data?.map((e, i) => (
          <Box
            key={i}
            bgcolor="white"
            mr="1rem"
            borderRadius="25px"
            width={["20rem", "21rem", "25rem", "25rem", "25rem"]}
            height={["11rem", "11rem", "14rem", "14rem", "14rem"]}
          >
            <img
              src={API_URL.baseUrl + API_URL.getBrandLogo + e.logo}
              style={{
                borderRadius: "25px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt=""
            />
          </Box>
        ))}
      </Marquee>
    </Box>
  );
});

export default Companies;

import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { API_URL } from "../../../Apis/config";
import { useState } from "react";
import FeatureModal from "../../../Components/Modal/FeatureModal";
import EastIcon from "@mui/icons-material/East";

const Card = ({ name, desc, logo, url, created_at, data }) => {
  const [open, setOpen] = useState(false);

  const checkDateIfNew = (date) => {
    const apiDateTime = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate - apiDateTime;
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const isWithinLast30Days = daysDifference <= 30;
    return isWithinLast30Days;
  };

  return (
    <>
      <Box
        border="1px solid grey"
        width="19rem"
        height="16rem"
        bgcolor="white"
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px="0.7rem"
        mb="0.5rem"
        mt="0.4rem"
        mr="0.5rem"
        ml="0.5rem"
        onClick={() => setOpen(true)}
      >
        <Stack justifyContent="center" alignItems="center" gap="1rem">
          <Box
            width="19rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="62%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Avatar
                src={API_URL.baseUrl + API_URL.getValueLogo + logo}
                style={{
                  width: "4.3rem",
                  height: "4.3rem",
                }}
                alt=""
              />
            </Box>
            <Box
              width="38%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {checkDateIfNew(created_at) ? (
                <Typography
                  style={{
                    height: "50%",
                    padding: "0.5rem",
                    backgroundColor: "#2F9300",
                    color: "white",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  NEW
                </Typography>
              ) : null}
            </Box>
          </Box>

          <Typography>{name ?? ""}</Typography>

          <Typography
            color="rgba(0, 0, 0, 0.70)"
            fontSize="14px"
            textAlign="center"
          >
            {desc ?? ""}
          </Typography>

          <IconButton>
            <EastIcon />
          </IconButton>
        </Stack>
      </Box>

      {open ? (
        <FeatureModal
          open={open}
          data={data}
          handleClose={() => setOpen(false)}
        />
      ) : null}
    </>
  );
};

export default Card;

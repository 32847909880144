import { useEffect, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import BookNowBanner from "./BookNowBanner";
import MainSection from "./MainSection";
import Announcements from "./Announcements";
import CarouselBlack from "./CarouselBlack";
import Companies from "./Companies";
import PodianLife from "./PodianLife";
import Cities from "./Cities";
import ClientSection from "./ClientSection";
import Playground from "./Playground";
import Numbers from "./Numbers";
import Features from "./Features";
import { GET_MASTER_LIST } from "../../Apis/home_api";

function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const res = await GET_MASTER_LIST();
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  const getEntriesByRange = (allowedIds) => {
    return data.filter((e) => allowedIds.includes(e.id));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Stack
          id="top"
          width="100%"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <MainSection data={data ? getEntriesByRange([10, 11, 12, 13]) : []} />
          <BookNowBanner />
          <Numbers />
          <Announcements />
          <Features />
          <CarouselBlack dataM={data ? getEntriesByRange([14, 15]) : []} />
          <Cities dataM={data ? getEntriesByRange([16, 17]) : []} />
          <Companies dataM={data ? getEntriesByRange([18, 19]) : []} />
          <PodianLife
            data={
              data
                ? getEntriesByRange([20, 21, 56, 57, 58, 59, 60, 61, 62])
                : []
            }
          />
          <ClientSection dataM={data ? getEntriesByRange([22, 23]) : []} />
          {/* <Playground /> */}
        </Stack>
      )}
    </>
  );
}

export default Home;

import { memo } from "react";
import { Box } from "@mui/material";
import Marquee from "react-fast-marquee";
import comp1 from "../../Assets/comp1.png";
import comp2 from "../../Assets/comp2.png";
import comp3 from "../../Assets/comp3.png";
import comp4 from "../../Assets/comp4.png";

const images = [comp1, comp2, comp3, comp4];

const Features = memo(() => {
  return (
    <Box
      sx={{
        width: "100%",
        py: ["2rem", "2rem", "3.5rem", "3.5rem", "3.5rem"],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3rem",
        overflow: "hidden",
      }}
    >
      <Marquee
        speed={85}
        loop={0}
        autoFill={true}
        style={{ width: "100%", overflow: "hidden" }}
      >
        {images?.map((e, i) => (
          <Box
            key={i}
            mr="1rem"
            borderRadius="25px"
            width={["10rem", "12rem", "100%", "100%", "100%"]}
          >
            <img
              src={e}
              style={{ borderRadius: "25px", width: "100%" }}
              alt=""
            />
          </Box>
        ))}
      </Marquee>
    </Box>
  );
});

export default Features;

import { Box } from "@mui/material";
import React from "react";

function TextFieldView({
  label,
  type = "text",
  bgcolor = "black",
  isBorder = true,
  width = "75%",
  responsiveW,
  name = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
}) {
  return (
    <Box sx={{ width: !width.length ? width : responsiveW }}>
      <input
        type={type}
        autoComplete="off"
        placeholder={label}
        name={name}
        value={value}
        style={{
          width: "100%",
          padding: "0.5rem",
          borderRadius: "4px",
          backgroundColor: bgcolor,
          color: bgcolor === "white" ? "black" : "white",
          outline: "0",
          border: "0",
          fontSize: "17px",
        }}
        onChange={onChange}
        onBlur={onBlur}
      />
      <div
        style={{
          border: isBorder
            ? bgcolor === "white"
              ? "1px solid gray"
              : "1px solid rgba(255, 255, 255, 0.6)"
            : "0",
          width: "100%",
          marginTop: "0.6rem",
        }}
      ></div>
    </Box>
  );
}

export default TextFieldView;

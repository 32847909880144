import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  SlideFromLeft,
  SlideFromRight,
  TextAnimationFade,
} from "../../Utils/Animations";
import { GET_MASTER_LIST } from "../../Apis/home_api";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_VALUES } from "../../Apis/aboutus";
import { API_URL } from "../../Apis/config";
import YourComponent from "./Scroll/YourComponent";

function About() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [cardData, setCardData] = useState([]);

  const getData = async () => {
    const res = await GET_MASTER_LIST();
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  const getCardValues = async () => {
    const res = await GET_VALUES();
    if (res && res.s) {
      setCardData(res.r);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    getCardValues();
  }, []);

  const getValue = (id) => {
    const specificObject = data.find((item) => item.id === id);
    return specificObject ? specificObject.value : "";
  };

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          {/*SECTION 1*/}
          <Stack
            width={["94%", "95%", "60%", "60%", "60%"]}
            alignItems="center"
            gap={["3rem", "3rem", "1.8rem", "1.8rem", "1.8rem"]}
            pt={["2rem", "2rem", "4rem", "4rem", "4rem"]}
            pb={["0rem", "0rem", "4rem", "4rem", "4rem"]}
          >
            <SlideFromLeft
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h3"
                color="#F8AF41"
                fontSize={["28px", "34px", "53px", "50px", "50px"]}
              >
                {getValue(39)}
              </Typography>
            </SlideFromLeft>

            <Stack gap="1rem">
              <SlideFromLeft>
                <div
                  dangerouslySetInnerHTML={{ __html: getValue(33) }}
                  style={{
                    width: "97%",
                    margin: "auto",
                    paddingBottom: "1rem",
                  }}
                />
              </SlideFromLeft>
            </Stack>
          </Stack>

          {/*SECTION 2*/}
          <Box
            width="100%"
            bgcolor="black"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={["3rem", "3rem", "4rem", "4rem", "4rem"]}
          >
            <Box
              width={["90%", "90%", "80%", "80%", "80%"]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display={["none", "none", "block", "block", "block"]}
                width="100%"
                height="100%"
              >
                <TextAnimationFade>
                  <img
                    src={
                      getValue(63) === null
                        ? require("../../Assets/AboutUs/aboutus.png")
                        : API_URL.baseUrl +
                          API_URL.getMasterImage +
                          getValue(63)
                    }
                    style={{ height: "100%", objectFit: "cover" }}
                    alt=""
                  />
                </TextAnimationFade>
              </Box>

              <Stack
                width={["100%", "100%", "50%", "50%", "50%"]}
                justifyContent="space-between"
                gap={["1rem", "1rem", "0", "0", "0"]}
              >
                <SlideFromRight>
                  <Typography
                    fontSize={["30px", "30px", "58px", "58px", "58px"]}
                    fontWeight="600"
                    color="white"
                  >
                    {getValue(34)}
                  </Typography>
                </SlideFromRight>

                <SlideFromRight>
                  <Typography
                    variant="h1"
                    fontSize={["12px", "13px", "22px", "22px", "22px"]}
                    lineHeight="32px"
                    color="white"
                  >
                    {getValue(35)}
                  </Typography>
                </SlideFromRight>

                <SlideFromRight>
                  <Typography lineHeight="28px" variant="transparent">
                    {getValue(36)}
                  </Typography>
                </SlideFromRight>
              </Stack>
            </Box>
          </Box>

          {/*SECTION 3*/}
          <Stack
            width="98%"
            gap="3rem"
            pt={["1.3rem", "1.3rem", "4rem", "4rem", "4rem"]}
            pb={["1.5rem", "1.5rem", "0", "0", "0"]}
          >
            <SlideFromLeft>
              <Stack justifyContent="center" alignItems="center">
                <Typography
                  variant="h3"
                  fontSize={["26px", "27px", "58px", "58px", "58px"]}
                >
                  {getValue(37)}
                </Typography>
                <Typography>{getValue(38)}</Typography>
              </Stack>
            </SlideFromLeft>

            {/* <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              {cardData?.map((e, i) => (
                <TextAnimationFade key={i}>
                  <Card name={e.name} desc={e.description} logo={e.logo} />
                </TextAnimationFade>
              ))}
            </Box> */}

            <SlideFromRight>
              <YourComponent data={cardData} />
            </SlideFromRight>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default About;

import { Button, Typography } from "@mui/material";
import { motion } from "framer-motion";

function ButtonView({
  label,
  btnColor = "#FAAF40",
  onClick = () => {},
  type = "",
}) {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      style={{
        border: "none",
        backgroundColor: "transparent",
        display: "inline-block",
      }}
    >
      <Button
        type={type}
        sx={{
          bgcolor: btnColor,
          color: "white",
          borderRadius: "30px",
          py: ["8px", "9px", "12px", "12px", "12px"],
          px: ["20px", "20px", "21px", "21px", "21px"],
          "&:hover": {
            bgcolor: "white",
            color: "#e88c07",
            border: "1px solid #e88c07",
            transition: "0.3s ease all",
          },
        }}
        onClick={onClick}
      >
        <Typography
          textTransform="none"
          fontWeight="500"
          fontSize={["14px", "14px", "16px", "16px", "16px"]}
        >
          {label}
        </Typography>
      </Button>
    </motion.div>
  );
}

export default ButtonView;

import { memo } from "react";
import { Box, Typography } from "@mui/material";

const Numbers = memo(({ number, label }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={1}
      flexWrap="wrap"
    >
      <Typography
        variant="h2"
        color="white"
        fontSize={["22px", "38px", "58px", "58px", "58px"]}
      >
        {number}
      </Typography>
      <Typography
        color="white"
        whiteSpace="pre-line"
        lineHeight="1.22"
        fontSize={["15px", "16px", "17px", "17px", "18px"]}
      >
        {label}
      </Typography>
    </Box>
  );
});

export default Numbers;

import { POSTAPI, API_URL } from "./config";

export const GET_IN_TOUCH = async (payload) => {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("email", payload.email);
  formData.append("mobile", payload.mobile);
  formData.append("address", payload.address);

  const res = await POSTAPI(API_URL.getInTouch, formData);
  return res;
};

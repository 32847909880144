import { Menu, MenuItem, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

const SubMenuLinks = ({
  openMenu = false,
  setOpenMenu = () => {},
  onClick,
}) => {
  const location = useLocation();

  return (
    <div>
      <Typography
        variant="navlink"
        sx={{
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        Discover
      </Typography>

      <Menu
        id="menu"
        anchorEl={openMenu}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        style={{ marginTop: "0.5rem" }}
      >
        <NavLink to="/podian" style={{ textDecoration: "none" }}>
          <MenuItem onClick={() => setOpenMenu(false)} sx={{ px: "1.5rem" }}>
            <Typography sx={{ fontSize: "1rem", color: "black" }}>
              PODIAN
            </Typography>
          </MenuItem>
        </NavLink>

        <NavLink to="/gallery" style={{ textDecoration: "none" }}>
          <MenuItem onClick={() => setOpenMenu(false)} sx={{ px: "1.5rem" }}>
            <Typography sx={{ fontSize: "1rem", color: "black" }}>
              Gallery
            </Typography>
          </MenuItem>
        </NavLink>

        {location.pathname === "/" ? (
          <a href="/#cities" style={{ textDecoration: "none" }}>
            <MenuItem onClick={() => setOpenMenu(false)} sx={{ px: "1.5rem" }}>
              <Typography sx={{ fontSize: "1rem", color: "black" }}>
                Available cities
              </Typography>
            </MenuItem>
          </a>
        ) : null}
      </Menu>
    </div>
  );
};

export default SubMenuLinks;

import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { GET_MASTER_LIST } from "../../Apis/home_api";
import CircularProgress from "@mui/material/CircularProgress";

const RefundPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await GET_MASTER_LIST();
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  const getValue = (id) => {
    const specificObject = data.find((item) => item.id === id);
    return specificObject ? specificObject.value : "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt="3rem"
          mb={["2.5rem", "2.5rem", "-3rem", "-3rem", "-3rem"]}
        >
          <Stack width={["92%", "92%", "86%", "86%", "86%"]} gap="3rem">
            <Typography
              variant="h3"
              fontSize={["30px", "30px", "54px", "54px", "54px"]}
              textAlign="center"
            >
              {getValue(53)}
            </Typography>
            <Stack
              width={["100%", "100%", "98%", "90%", "85%"]}
              m={["auto", "auto", "none", "none", "none"]}
              gap="1.8rem"
            >
              <Typography fontSize={["16px", "17px", "19px", "19px", "19px"]} dangerouslySetInnerHTML={{ __html: getValue(52) }}>
                
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default RefundPolicy;

import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_PODIAN_OF_MONTH = async () => {
  const res = await GETAPI(API_URL.getPodianOfMonth);
  return res;
};

export const GET_PODIAN_STATS = async () => {
  const res = await GETAPI(API_URL.getPodianStats);
  return res;
};

export const GET_FOR_PODIAN = async () => {
  const res = await GETAPI(API_URL.getForPodian);
  return res;
};

export const GET_JOIN_PROCESS_PODIAN = async () => {
  const res = await GETAPI(API_URL.getJoinProcessPodian);
  return res;
};

//GET BROCHURE
export const GET_BROCHURE = async (payload) => {
  const formData = new FormData();
  formData.append("first_name", payload.firstName);
  formData.append("last_name", payload.lastName);
  formData.append("city", payload.city);
  formData.append("email", payload.email);
  formData.append("mobile", payload.mobile);

  const res = await POSTAPI(API_URL.getBrochurePodian, formData);
  return res;
};

import { Button, Typography } from "@mui/material";
import { motion } from "framer-motion";

function ButtonBase({ label, onClick = null }) {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      style={{
        border: "none",
        display: "inline-block",
      }}
    >
      <Button
        sx={{
          bgcolor: "white",
          color: "white",
          borderRadius: "30px",
          py: "12px",
          px: "21px",
          "&:hover": {
            bgcolor: "white",
            transition: "0.3s ease all",
          },
        }}
        onClick={onClick}
      >
        <Typography textTransform="none" fontWeight="500" color="black">
          {label}
        </Typography>
      </Button>
    </motion.div>
  );
}

export default ButtonBase;

import { memo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "../../Modules/Home.module.css";
import { useTheme } from "@emotion/react";
import { TextAnimation, TextAnimationFade } from "../../Utils/Animations";
import { PLAY_STORE_USER } from "../../Utils/links";
import { API_URL } from "../../Apis/config";
import { ANALYTICS } from "../../Apis/firebase";
import { logEvent } from "firebase/analytics";

const MainSection = memo(({ data = [] }) => {
  const theme = useTheme();

  return (
    <Stack
      width={["100%", "98%", "90%", "86%", "86%"]}
      alignItems="center"
      justifyContent="center"
      pt="1.2rem"
      gap="1.2rem"
      mb={["2.5rem", "2.5rem", "2rem", "5.7rem", "5.7rem"]}
    >
      <div>
        <TextAnimation>
          <Box
            display="flex"
            flexDirection={["column", "column", "row", "row", "row"]}
            alignItems="center"
            gap={["0.2rem", "0.2rem", "0", "0", "0"]}
          >
            <Typography
              variant="h1"
              textAlign="center"
              fontSize={["16px", "18px", "22px", "22px", "22px"]}
            >
              Book photoshoot in just few seconds
            </Typography>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  fontSize: ["16px", "18px", "22px", "22px", "22px"],
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                ⚡️
              </Typography>
              <Typography
                sx={{
                  fontSize: ["16px", "17px", "22px", "22px", "22px"],
                  fontWeight: "500",
                  letterSpacing: "1px",
                  borderBottom: "1px solid transparent",
                  color: theme.palette.primary.second,
                  "&:hover": {
                    cursor: "pointer",
                    borderBottom: `1px solid ${theme.palette.primary.second}`,
                  },
                }}
                onClick={() => {
                  window.open(PLAY_STORE_USER, "_blank");
                  logEvent(ANALYTICS, 'web_download_click_play_store', {
                    label: 'download',
                    action: "download_app",
                  });
                }}
              >
                Book Now
              </Typography>
            </Box>
          </Box>
        </TextAnimation>
      </div>

      <TextAnimationFade style={{ width: "100%" }}>
        <Box sx={{
          px:{
            xs:1,
            md:0
          },
        }}>
          <Box
            className={styles.hero_banner}
            style={{
              backgroundImage: `url(${
                API_URL.baseUrl + API_URL.getMasterImage + data[3]?.value
              })`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              width: "100%",
              height: ["100%", "100%", "100vh", "100vh", "100vh"],
            }}
          >
            <div style={{ padding: "5rem 0" }}>
              <TextAnimation
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: ["30px", "34px", "56px", "56px", "56px"],
                    color: "white",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {data ? data[0]?.value : "PIXELS THAT INSPIRES"}
                </Typography>
                <Stack gap="0.7rem">
                  <Typography
                    color="white"
                    fontSize={["14px", "14px", "25px", "25px", "25px"]}
                    textAlign="center"
                    px="0.4rem"
                    display="flex"
                    flexWrap="wrap"
                  >
                    {data
                      ? data[1]?.value
                      : "POD - India’s first personal photographer booking platform."}
                  </Typography>
                  <Typography
                    color={theme.palette.primary.second}
                    fontSize={["14px", "14px", "25px", "25px", "25px"]}
                    textAlign="center"
                  >
                    {data ? data[2]?.value : "#kahibhikabhibhi 📸"}
                  </Typography>
                </Stack>
              </TextAnimation>
            </div>
          </Box>
        </Box>
      </TextAnimationFade>
    </Stack>
  );
});

export default MainSection;

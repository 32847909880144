import axios from "axios";

const development_mode = false;

export const API_URL = {
  baseUrl: development_mode
    ? "https://dev.podahmedabad.com/"
    : "https://api.photographerondemand.in/",
   
  getMasterList: "landing/web/getMaster",
  getMasterImage: "uploads/master/",

  getAnnouncements: "landing/web/getAnnouncements",
  getAnnouncementById: "landing/web/getAnnouncementDetails",
  announcementImage: "uploads/web/announcement/",

  getCities: "landing/web/getCities",
  getCityById: "landing/web/getCityDetails",
  cityImage: "uploads/web/city/",
  
  getReviews: "landing/web/getRateReview",
  getReviewPhoto: "uploads/users/pp/",

  getGallery: "landing/web/getGallery",

  getPortfolio: "landing/web/getPortfolio",

  getBrands: "landing/web/getBrands",
  getBrandById: "landing/web/getBrandDetails",
  getBrandLogo: "uploads/web/brand/",

  getStatistics: "landing/web/getStatistics",

  subscribeBlogs: "landing/web/subscribeBlogs",

  getFeatures: "landing/web/getFeatures",
  featureLogo: "uploads/web/feature/",

  getPodianOfMonth: "landing/web/getPodianOfMonth",
  getPodianStats: "landing/web/getPodianStatistics",
  getForPodian: "landing/web/getForPodians",
  getJoinProcessPodian: "landing/web/getJoinProcessPodian",
  ForPodianPhoto: "uploads/web/forpodian/",
  getBrochurePodian: "landing/web/getBrochurePodian",

  getFaqList: "landing/web/getFaq",
  getInTouch: "landing/web/getInTouch",

  getValues: "landing/web/getValues",
  getValueLogo: "uploads/web/value/",
};

export const API = axios.create({
  baseURL: API_URL.baseUrl,
});

export async function GETAPI(url, payload = "") {
  try {
    const result = await API.get(url, {
      headers: null,
    });

    return result.data;
  } catch (e) {
    console.log("Error", e);
    return null;
  }
}

export async function POSTAPI(url, payload = "", headers = null) {
  try {
    const res = await axios.post(API_URL.baseUrl + url, payload, {
      headers: null,
    });
    console.log(res.data);
    return res.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SlideFromLeft } from "../../Utils/Animations";
function PodianAbout() {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        {/*SECTION 1*/}
        <Stack
          width={["94%", "95%", "60%", "60%", "60%"]}
          alignItems="center"
          gap={["3rem", "3rem", "1.8rem", "1.8rem", "1.8rem"]}
          pt={["2rem", "2rem", "4rem", "4rem", "4rem"]}
          pb={["0rem", "0rem", "4rem", "4rem", "4rem"]}
        >
          <SlideFromLeft
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h3"
              color="#F8AF41"
              fontSize={["28px", "34px", "53px", "50px", "50px"]}
            >
              About PODIAN
            </Typography>
          </SlideFromLeft>

          <Stack gap="1rem">
            <SlideFromLeft >
              Introducing the PODIAN App: the ultimate tool designed to empower
              on-demand and freelance photographers to capture moments with ease
              and elevate their professional journey. 📸📱
            </SlideFromLeft>
            <SlideFromLeft>
              Are you a talented photographer looking for a seamless way to
              manage your bookings, track your progress, and deliver exceptional
              service to your clients? Look no further than the PODIAN App,
              crafted exclusively for on-demand and freelance photographers
              affiliated with POD. This robust tool is designed to streamline
              your workflow, keep you organized, and help you advance your
              photography career effortlessly. 💪📆📈
            </SlideFromLeft>
            <SlideFromLeft>
              With the PODIAN App, managing your incoming orders has never been
              easier. 📥💼 The intuitive interface allows you to access and
              organize your bookings in just a few taps. Stay informed about the
              status of your confirmed and completed orders, ensuring that
              you’re always on top of your schedule. 📲✅ The app's real-time
              notifications keep you updated on any changes or new
              opportunities, allowing you to focus on what you do best –
              capturing stunning photographs.
            </SlideFromLeft>
            <SlideFromLeft>
              Client feedback is crucial for continuous improvement, and the
              PODIAN App facilitates this seamlessly. Through the app’s rating
              system, you can receive valuable feedback from your clients,
              helping you understand your strengths and areas for improvement.
              ⭐📝🔍 The PODIAN Score, an aggregate measure of your overall
              performance, allows you to track your progress and strive for
              excellence. By consistently delivering high-quality service, you
              can boost your PODIAN Score and build a stellar reputation in the
              industry.
            </SlideFromLeft>
            <SlideFromLeft>
              Navigating to your shoot destinations is a breeze with the
              integrated map feature within the app. 🗺️🚀 Whether you’re heading
              to a local park for a portrait session or a remote location for a
              special event, the app provides clear directions, helping you
              reach your destination on time and stress-free. This ensures that
              you can focus on preparing for the shoot and delivering your best
              work.
            </SlideFromLeft>
            <SlideFromLeft>
              The PODIAN App is more than just a scheduling tool; it’s a
              comprehensive platform designed to enhance productivity and client
              satisfaction. It empowers on-demand and freelance photographers to
              manage their bookings efficiently and provide exceptional service.
              By using the app, you can ensure that your clients receive a
              professional and seamless experience from start to finish.
            </SlideFromLeft>
            <SlideFromLeft>
              Downloading the PODIAN App today means embracing convenience,
              efficiency, and professionalism. 📲🌟📸 As a PODIAN photographer,
              you’ll have all the tools you need to capture moments, build your
              reputation, and take your photography career to new heights. The
              app’s user-friendly interface and powerful features make it an
              indispensable part of your photography toolkit.
            </SlideFromLeft>
            <SlideFromLeft>
              With the PODIAN App, you’re not just managing your business;
              you’re elevating it. The app’s comprehensive features are designed
              to help you stay organized, improve your skills, and connect with
              clients more effectively. From tracking your bookings and progress
              to navigating to shoots and receiving feedback, every aspect of
              the app is tailored to support your growth as a photographer.
            </SlideFromLeft>
            <SlideFromLeft>
              In summary, the PODIAN App is the ultimate companion for on-demand
              and freelance photographers. It offers a seamless way to manage
              bookings, track performance, and provide top-notch service to
              clients. By leveraging this powerful tool, you can focus on what
              you love – capturing beautiful moments and delivering exceptional
              photography. Embrace the future of photography with the PODIAN
              App, and watch your career soar to new heights. 📸📱
            </SlideFromLeft>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default PodianAbout;

import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TextAnimation = ({ children, style = {} }) => {
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={textVariants}
      transition={{ duration: 1 }}
      style={{ width: "100%", ...style }}
    >
      {children}
    </motion.div>
  );
};

const SlideFromRight = ({ children, style = {} }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: "100px" }}
      animate={controls}
      transition={{ duration: 1 }}
      style={{
        width: "100%",
        ...style,
      }}
    >
      {children}
    </motion.div>
  );
};

const SlideFromLeft = ({ children, style = {} }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: "-100px" }}
      animate={controls}
      transition={{ duration: 1 }}
      style={{ width: "100%", ...style }}
    >
      {children}
    </motion.div>
  );
};

const TextAnimationFade = ({ children, style = {} }) => {
  //Framer motion
  const textControls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      textControls.start({ opacity: 1, y: 0 });
    }
  }, [inView, textControls]);

  return (
    <motion.h1
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={textControls}
      transition={{ duration: 0.8, ease: "easeOut" }}
      style={{ ...style }}
    >
      {children}
    </motion.h1>
  );
};

export { TextAnimation, SlideFromRight, SlideFromLeft, TextAnimationFade };

import React from 'react';
import AllRoutes from './routes/AllRoutes';
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  return (
    <>
      <AllRoutes />
    </>
  );
};

export default App;


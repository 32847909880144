import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "../../Modules/Podian.module.css";
import Numbers from "./Numbers";
import PhotographerCard from "./PhotographerCard";
import Steps from "./Steps";
import Brochure from "./Brochure";
import { TextAnimation, TextAnimationFade } from "../../Utils/Animations";
import { GET_MASTER_LIST } from "../../Apis/home_api";
import { GET_FOR_PODIAN, GET_PODIAN_STATS } from "../../Apis/podian_api";
import PodianOfMonth from "./PodianOfMonth";
import CircularProgress from "@mui/material/CircularProgress";
import { APP_STORE_PODIAN, PLAY_STORE_PODIAN } from "../../Utils/links";
import { API_URL } from "../../Apis/config";
import { ANALYTICS } from "../../Apis/firebase";
import { logEvent } from "firebase/analytics";

const Podian = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [podianData, setPodianData] = useState([]);
  const getData = async () => {
    const res = await GET_MASTER_LIST();
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  const getStats = async () => {
    const res = await GET_PODIAN_STATS();
    if (res && res.s) {
      setStats(res.r);
    }
  };

  const getForPodian = async () => {
    const res = await GET_FOR_PODIAN();
    if (res && res.s) {
      setPodianData(res.r);
    }
  };

  const getEntriesByRange = (allowedIds) => {
    return data.filter((e) => allowedIds.includes(e.id))[0];
  };
  // const getEntriesByRangeData = (allowedIds) => {
  //   return data.filter((e) => allowedIds.includes(e.id));
  // };

  useEffect(() => {
    getData();
    getStats();
    getForPodian();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={["3rem", "3rem", "2rem", "3rem", "3rem"]}
          overflow="hidden"
        >
          {/*First Section*/}
          <Stack
            width={["95%", "95%", "86%", "90%", "90%"]}
            mb={["3rem", "3rem", "0rem", "0rem", "0rem"]}
          >
            <TextAnimation>
              <Stack
                width="100%"
                alignItems="center"
                justifyContent="center"
                gap="2rem"
              >
                <Typography
                  variant="h3"
                  fontSize={["21px", "21px", "50px", "58px", "58px"]}
                  width={["100%", "100%", "100%", "80%", "80%"]}
                  textAlign="center"
                >
                  {getEntriesByRange([27])?.value}
                </Typography>

                <Typography
                  variant="little"
                  fontSize={["14px", "15px", "18px", "18px", "18px"]}
                  width={["95%", "95%", "100%", "80%", "80%"]}
                  textAlign="center"
                >
                  {getEntriesByRange([28])?.value}
                </Typography>

                <Box display="flex" gap="1rem">
                  <Box
                    display="flex"
                    justifyContent="center"
                    width={["5.3rem", "5.3rem", "8rem", "8rem", "8rem"]}
                  >
                    <img
                      src={require("../../Assets/Icons/playstore.png")}
                      alt=""
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(PLAY_STORE_PODIAN, "_blank");
                        logEvent(ANALYTICS, 'web_download_click_play_store', {
                          label: 'download',
                          action: "download_app",
                        });
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={["5.3rem", "5.3rem", "8rem", "8rem", "8rem"]}
                  >
                    <img
                      src={require("../../Assets/Icons/appstore.png")}
                      alt=""
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(APP_STORE_PODIAN, "_blank");
                        logEvent(ANALYTICS, 'web_download_click_app_store', {
                          label: 'download',
                          action: "download_app",
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </TextAnimation>

            <TextAnimation>
              <div className={styles.firstSection}>
                <img
                  src={
                    getEntriesByRange([31])?.value !== null
                      ? API_URL.baseUrl +
                        API_URL.getMasterImage +
                        getEntriesByRange([31]).value
                      : require("../../Assets/iphone.png")
                  }
                  style={{
                    // width: "88%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "1.2rem",
                  }}
                  alt=""
                />
              </div>
            </TextAnimation>
          </Stack>

          {/*Second Section*/}
          <PodianOfMonth />

          {/*Third Section*/}
          <Stack
            width="100%"
            bgcolor="black"
            alignItems="center"
            gap="2rem"
            py="3rem"
          >
            <TextAnimationFade
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box
                width={["89%", "85%", "97%", "90%", "88%"]}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <div>
                  <Numbers
                    number={`${stats.cities}+`}
                    label="No. of Active Cities"
                  />
                </div>

                <div>
                  <Numbers number={stats.podians} label="No. of PODIANs" />
                </div>

                <div style={{ alignSelf: "flex-end" }}>
                  <Numbers number={`${stats.downloads}+`} label="Downloads" />
                </div>
              </Box>
            </TextAnimationFade>

            <Box
              width={["89%", "85%", "97%", "90%", "88%"]}
              display="flex"
              gap={["1rem", "1.1rem", "1.2rem", "2rem", "2rem"]}
              flexDirection={["column", "column", "row", "row", "row"]}
            >
              {podianData?.map((e, i) => (
                <PhotographerCard
                  key={i}
                  text={e.name}
                  photo={e.photo}
                  buttonLabel={e.label}
                  buttonURL={e.url}
                />
              ))}
            </Box>
          </Stack>

          {/*Fourth Section*/}
          {/* <VideoSection data={getEntriesByRangeData([29, 30, 31])} /> */}
          <Steps />

          {/*Fifth Section*/}
          <Brochure value={getEntriesByRange([32])?.value} />
        </Box>
      )}
    </>
  );
};

export default Podian;

import { memo } from "react";
import { Button, Typography } from "@mui/material";
import styles from "../../Modules/Podian.module.css";
import { motion } from "framer-motion";
import { API_URL } from "../../Apis/config";
import podianPhoto from "../../Assets/podian_photographer.png";
import { SlideFromRight } from "../../Utils/Animations";

const PhotographerCard = memo(({ text, photo, buttonLabel, buttonURL }) => {
  return (
    <SlideFromRight width="100%">
      <div
        className={styles.secondSection}
        style={{
          backgroundImage: photo
            ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${
                API_URL.baseUrl + API_URL.ForPodianPhoto + photo
              })`
            : `url(${podianPhoto})`,
        }}
      >
        <Typography
          width="100%"
          height="50%"
          color="white"
          fontSize={["1rem", "1.2rem", "1.3rem", "1.3rem", "1.5rem"]}
        >
          {text}
        </Typography>

        <div
          style={{
            width: "100%",
          }}
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <Button
              sx={{
                bgcolor: "white",
                color: "white",
                borderRadius: "30px",
                py: ["7px", "7px", "8px", "10px", "12px"],
                px: ["14px", "14px", "19px", "19px", "21px"],
                "&:hover": {
                  bgcolor: "white",
                  transition: "0.3s ease all",
                },
              }}
              onClick={() => {
                window.open(buttonURL, "_blank");
              }}
            >
              <Typography
                fontSize={["13px", "14px", "14px", "14px", "16px"]}
                textTransform="none"
                fontWeight="500"
                color="black"
              >
                {buttonLabel}
              </Typography>
            </Button>
          </motion.button>
        </div>
      </div>
    </SlideFromRight>
  );
});

export default PhotographerCard;

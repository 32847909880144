import { useState, useEffect, memo } from "react";
import { useTheme } from "@emotion/react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import main_logo from "../../Assets/main_logo.png";
import POD from "../../Assets/POD.png";
import ButtonView from "../../Components/ButtonView";
import TextFieldView from "../../Components/TextFieldView";
import { motion, useAnimation } from "framer-motion";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import SubMenuLegal from "../../Components/SubMenus/SubMenuLegal";
import SubMenuFeatures from "../../Components/SubMenus/SubMenuFeatures";
import { GET_MASTER_LIST, SUBSCRIBE_TO_BLOGS } from "../../Apis/home_api";
import { ToastContainer, toast } from "react-toastify";
import { GET_FEATURES } from "../../Apis/feature_api";
import {
  APP_STORE_USER,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  PLAY_STORE_USER,
  YOUTUBE_LINK,
} from "../../Utils/links";
import { ANALYTICS } from "../../Apis/firebase";
import { logEvent } from "firebase/analytics";

const currentYear = new Date().getFullYear();

const Footer = memo(() => {
  const theme = useTheme();
  const path = useLocation();

  const [data, setData] = useState([]);
  const [openMenu, setOpenMenu] = useState(null);
  const [openFeatures, setOpenFeatures] = useState(null);
  const [email, setEmail] = useState("");
  const [featuresData, setFeaturesData] = useState([]);

  const getData = async () => {
    const res = await GET_MASTER_LIST();

    const allowedIds = [24, 25, 26];

    if (res && res.s) {
      const filteredData = res?.r.filter((e) => allowedIds.includes(e.id));
      setData(filteredData ?? []);
    }
  };

  const getFeaturesData = async () => {
    const res = await GET_FEATURES();
    if (res && res.s) {
      setFeaturesData(res.r);
    }
  };

  const handleSubmit = async () => {
    if (validateEmail(email)) {
      const res = await SUBSCRIBE_TO_BLOGS({ email });
      if (res && res.s) {
        toast("Successfully subscribed!");
        logEvent(ANALYTICS, 'web_click_subscribe_blogs', {
          label: 'Subscribe to Blogs',
          action: 'click_subscribe',
        });        
      } else {
        toast(res.m && res.m);
      }
    } else {
      toast("Invalid email address!");
    }
    setEmail("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    getData();
    getFeaturesData();
  }, []);

  //Framer motion
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);
  //Framer motion

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to "auto" for instant scroll
    });
  };

  return (
    <>
      <Stack
        id="menu"
        mt={path.pathname === "/" ? "3.5rem" : "0"}
        width="100%"
        display={["none", "none", "flex", "flex", "flex"]}
      >
        {/*BLACK BOX ON FOOTER*/}
        {path.pathname === "/" && (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: "50px" }}
            animate={controls}
            transition={{ duration: 0.8, ease: "easeOut" }}
            style={{
              width: "86%",
              height: "10rem",
              backgroundColor: "black",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 2rem",
              position: "absolute",
              alignSelf: "center",
            }}
          >
            <Box flex={1} height="70%">
              <Typography
                fontSize={["38px", "38px", "30px", "38px", "38px"]}
                color={theme.palette.primary.main}
                fontWeight="500"
                sx={{ userSelect: "none" }}
              >
                Stay Updated With Us
              </Typography>
              <Typography
                fontSize={["20px", "20px", "17px", "20px", "20px"]}
                color="white"
                sx={{ userSelect: "none" }}
              >
                Subscribe the blogs, to know about photography
              </Typography>
            </Box>

            <Box
              flex={1}
              height="70%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextFieldView
                label="Email"
                type="email"
                responsiveW={["75%", "75%", "50%", "75%", "75%"]}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <ButtonView label="Notify Me" onClick={handleSubmit} />

              <ToastContainer />
            </Box>
          </motion.div>
        )}

        <Box
          sx={{
            bgcolor: theme.palette.primary.main,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "5.25rem",
          }}
        >
          <Stack
            width={["35%", "35%", "40%", "35%", "35%"]}
            height="210px"
            justifyContent="space-between"
            pl={["4.5rem", "4.5rem", "1rem", "4.5rem", "4.5rem"]}
            mt={path.pathname === "/" ? "8rem" : "4rem"}
            borderRight={`2px solid ${theme.palette.primary.second}`}
          >
            <NavLink to="/" onClick={scrollToTop}>
              <Box display="flex" alignItems="center" gap={1}>
                <Avatar
                  alt=""
                  src={main_logo}
                  sx={{
                    objectFit: "fill",
                    width: "2.8rem",
                    height: "100%",
                    borderRadius: "0",
                  }}
                />
                <img
                  src={POD}
                  alt=""
                  sx={{
                    objectFit: "fill",
                    width: "1.5rem",
                    height: "100%",
                    borderRadius: "0",
                  }}
                />
              </Box>
            </NavLink>

            <Typography
              variant="little"
              fontSize={["16px", "16px", "15px", "16px", "16px"]}
              flexWrap="wrap"
              pr="0.8rem"
            >
              {data ? data[0]?.value : ""}
            </Typography>

            <Stack>
              <Typography
                fontSize={["18px", "18px", "15px", "18px", "18px"]}
                fontWeight="600"
              >
                {data ? data[1]?.value : "Office Location"}
              </Typography>
              <Typography
                variant="little"
                fontSize={["16px", "16px", "15px", "16px", "16px"]}
              >
                {data ? data[2]?.value : ""}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            width={["65%", "65%", "70%", "65%", "65%"]}
            height="100%"
            justifyContent="center"
            gap="3rem"
            pl={["4.5rem", "4.5rem", "0rem", "4.5rem", "4.5rem"]}
            mt={path.pathname === "/" ? "8rem" : "4rem"}
          >
            <Box
              height="210px"
              display="flex"
              justifyContent="space-between"
              px={["2rem", "2rem", "0.5rem", "2rem", "2rem"]}
            >
              <Stack gap={2} height="100%">
                <Typography fontWeight="600">Menu</Typography>
                <Stack height="100%" gap="0.25rem">
                  <div style={{ textDecoration: "none", userSelect: "none" }}>
                    <SubMenuFeatures
                      label="Features"
                      openMenu={openFeatures}
                      setOpenMenu={(isOpen) => setOpenFeatures(isOpen)}
                      onClick={(event) => setOpenFeatures(event.currentTarget)}
                      data={featuresData}
                    />
                  </div>

                  <Link to="/gallery" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="footerlink"
                      fontSize={["18px", "18px", "16px", "18px", "18px"]}
                    >
                      Gallery
                    </Typography>
                  </Link>

                  {path.pathname === "/" && (
                    <a href="/#cities" style={{ textDecoration: "none" }}>
                      <Typography
                        variant="footerlink"
                        fontSize={["18px", "18px", "16px", "18px", "18px"]}
                      >
                        Available Cities
                      </Typography>
                    </a>
                  )}

                  <Link to="/podian" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="footerlink"
                      fontSize={["18px", "18px", "16px", "18px", "18px"]}
                    >
                      Join PODIANS
                    </Typography>
                  </Link>
                </Stack>
              </Stack>

              <Stack gap={2} height="100%">
                <Typography
                  fontWeight="600"
                  fontSize={["18px", "18px", "16px", "18px", "18px"]}
                >
                  Company
                </Typography>
                <Stack height="100%" gap="0.25rem">
                  <Link to="/aboutus" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="footerlink"
                      fontSize={["18px", "18px", "16px", "18px", "18px"]}
                    >
                      About
                    </Typography>
                  </Link>
                  <Link to="/contactus" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="footerlink"
                      fontSize={["18px", "18px", "16px", "18px", "18px"]}
                    >
                      Contact Us
                    </Typography>
                  </Link>
                  <Link to="/faq" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="footerlink"
                      fontSize={["18px", "18px", "16px", "18px", "18px"]}
                    >
                      FAQ's
                    </Typography>
                  </Link>

                  <a
                    href="#menu"
                    style={{ textDecoration: "none", userSelect: "none" }}
                  >
                    <SubMenuLegal
                      label="Discover"
                      openMenu={openMenu}
                      setOpenMenu={(isOpen) => setOpenMenu(isOpen)}
                      onClick={(event) => setOpenMenu(event.currentTarget)}
                    />
                  </a>
                </Stack>
              </Stack>

              <Stack gap={2} height="100%">
                <Typography fontWeight="600">Follow on</Typography>
                <Stack height="100%" gap="0.6rem">
                  <Box display="flex" gap="1rem">
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => window.open(FACEBOOK_LINK, "_blank")}
                    >
                      <Avatar
                        src={require("../../Assets/Icons/Facebook.png")}
                        alt=""
                        sx={{
                          cursor: "pointer",
                          border: "2px solid transparent",
                          "&:hover": {
                            border: "2px solid white",
                          },
                        }}
                      />
                    </motion.button>

                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => window.open(INSTAGRAM_LINK, "_blank")}
                    >
                      <Avatar
                        src={require("../../Assets/Icons/Instagram.png")}
                        alt=""
                        sx={{
                          cursor: "pointer",
                          border: "2px solid transparent",
                          "&:hover": {
                            border: "2px solid white",
                          },
                        }}
                      />
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => window.open(LINKEDIN_LINK, "_blank")}
                    >
                      <Avatar
                        src={require("../../Assets/Icons/LinkedIn.png")}
                        alt=""
                        sx={{
                          cursor: "pointer",
                          border: "2px solid transparent",
                          "&:hover": {
                            border: "2px solid white",
                          },
                        }}
                      />
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => window.open(YOUTUBE_LINK, "_blank")}
                    >
                      <Avatar
                        src={require("../../Assets/Icons/YouTube.png")}
                        alt=""
                        sx={{
                          cursor: "pointer",
                          border: "2px solid transparent",
                          "&:hover": {
                            border: "2px solid white",
                          },
                        }}
                      />
                    </motion.button>
                  </Box>
                  <Typography fontWeight="600">Download On</Typography>
                  <Box display="flex" gap="1rem">
                    <img
                      src={require("../../Assets/Icons/playstore.png")}
                      alt=""
                      style={{
                        objectFit: "fill",
                        width: "8rem",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(PLAY_STORE_USER, "_blank")}
                    />
                    <img
                      src={require("../../Assets/Icons/appstore.png")}
                      alt=""
                      style={{
                        objectFit: "fill",
                        width: "8rem",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(APP_STORE_USER, "_blank")}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>

        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          bgcolor={theme.palette.primary.main}
        >
          <Box
            borderTop={`2px solid ${theme.palette.primary.second}`}
            width="86%"
            py="1rem"
            mt="2rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
          >
            <Typography
              variant="little"
              fontSize={["18px", "18px", "17px", "18px", "18px"]}
            >
              Copyright @{currentYear} by Podian Pvt Ltd.
            </Typography>
          </Box>
        </Box>
      </Stack>

      {/*FOR SMALLER SCREENS*/}
      <Box
        display={["flex", "flex", "none", "none", "none"]}
        sx={{
          bgcolor: theme.palette.primary.main,
          borderTop: "3px solid black",
          width: "100%",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "1.4rem",
          pt: "1.8rem",
          pb: "0.8rem",
          pl: "1.6rem",
          pr: "0.8rem",
        }}
      >
        <NavLink to="/" onClick={scrollToTop}>
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar
              alt=""
              src={main_logo}
              sx={{
                objectFit: "fill",
                width: "2.3rem",
                height: "100%",
                borderRadius: "0",
              }}
            />
            <img
              src={POD}
              alt=""
              style={{
                objectFit: "fill",
                width: "3.8rem",
                height: "100%",
                borderRadius: "0",
              }}
            />
          </Box>
        </NavLink>

        <Box flex={1} height="70%">
          <Typography
            fontSize={["17px", "17px", "17px", "20px", "20px"]}
            color="black"
            fontWeight="500"
            sx={{ userSelect: "none", mb: "0.2rem" }}
          >
            Stay Updated With Us,
          </Typography>
          <Typography
            fontSize={["14px", "15px", "17px", "20px", "20px"]}
            color="#3c3c3c"
            sx={{ userSelect: "none" }}
          >
            Subscribe the blogs, to know about photography
          </Typography>

          <Box
            flex={1}
            mt="1.3rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <TextFieldView
              label="Email"
              type="email"
              bgcolor="white"
              isBorder={false}
              responsiveW={["90%", "90%", "50%", "75%", "75%"]}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ButtonView
              label="Notify Me"
              btnColor="#3c3c3c"
              onClick={handleSubmit}
            />

            <ToastContainer />
          </Box>
        </Box>

        <Stack gap={1} height="100%">
          <Typography fontWeight="600">Menu</Typography>
          <Stack height="100%" gap="0.25rem">
            <Link to="/gallery" style={{ textDecoration: "none" }}>
              <Typography
                color="#3c3c3c"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                Gallery
              </Typography>
            </Link>

            <Link to="/podian" style={{ textDecoration: "none" }}>
              <Typography
                color="#3c3c3c"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                Join PODIANS
              </Typography>
            </Link>
          </Stack>
        </Stack>

        <Stack gap={1} height="100%">
          <Typography fontWeight="600">Company</Typography>
          <Stack height="100%" gap="0.25rem">
            <Link to="/aboutus" style={{ textDecoration: "none" }}>
              <Typography
                variant="footerlink"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                About
              </Typography>
            </Link>

            <Link to="/contactus" style={{ textDecoration: "none" }}>
              <Typography
                variant="footerlink"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                Contact Us
              </Typography>
            </Link>

            <Link to="/faq" style={{ textDecoration: "none" }}>
              <Typography
                variant="footerlink"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                FAQ's
              </Typography>
            </Link>

            <Link to="/termsofservice" style={{ textDecoration: "none" }}>
              <Typography
                variant="footerlink"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                Terms & Conditions
              </Typography>
            </Link>

            <Link to="/privacypolicy" style={{ textDecoration: "none" }}>
              <Typography
                variant="footerlink"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                Privacy policy
              </Typography>
            </Link>

            <Link to="/refundpolicy" style={{ textDecoration: "none" }}>
              <Typography
                variant="footerlink"
                fontSize={["15px", "15px", "16px", "18px", "18px"]}
              >
                Refund policy
              </Typography>
            </Link>
          </Stack>
        </Stack>

        <Stack gap={0.8} flexWrap="wrap">
          <Typography
            fontSize={["15px", "15px", "16px", "18px", "18px"]}
            fontWeight="600"
          >
            {data ? data[1]?.value : "Office Location"}
          </Typography>
          <Typography
            variant="little"
            fontSize={["15px", "15px", "16px", "18px", "18px"]}
            flexWrap="wrap"
          >
            {data ? data[2]?.value : ""}
          </Typography>
        </Stack>

        <Stack gap={1.8} height="100%">
          <Typography fontWeight="600">Follow on</Typography>
          <Stack height="100%" gap="0.6rem">
            <Box display="flex" gap="0.8rem" flexWrap="wrap">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  width: "2rem",
                  height: "2rem",
                }}
                onClick={() => window.open(FACEBOOK_LINK, "_blank")}
              >
                <Avatar
                  src={require("../../Assets/Icons/Facebook.png")}
                  alt=""
                  sx={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    border: "2px solid transparent",
                    "&:hover": {
                      border: "2px solid white",
                    },
                  }}
                />
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  width: "2rem",
                  height: "2rem",
                }}
                onClick={() => window.open(INSTAGRAM_LINK, "_blank")}
              >
                <Avatar
                  src={require("../../Assets/Icons/Instagram.png")}
                  alt=""
                  sx={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    border: "2px solid transparent",
                    "&:hover": {
                      border: "2px solid white",
                    },
                  }}
                />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  width: "2rem",
                  height: "2rem",
                }}
                onClick={() => window.open(LINKEDIN_LINK, "_blank")}
              >
                <Avatar
                  src={require("../../Assets/Icons/LinkedIn.png")}
                  alt=""
                  sx={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    border: "2px solid transparent",
                    "&:hover": {
                      border: "2px solid white",
                    },
                  }}
                />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  width: "2rem",
                  height: "2rem",
                }}
                onClick={() => window.open(YOUTUBE_LINK, "_blank")}
              >
                <Avatar
                  src={require("../../Assets/Icons/YouTube.png")}
                  alt=""
                  sx={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    border: "2px solid transparent",
                    "&:hover": {
                      border: "2px solid white",
                    },
                  }}
                />
              </motion.button>
            </Box>
          </Stack>
          <Typography fontWeight="600">Download On</Typography>
          <Box display="flex" gap="0.7rem" flexWrap="wrap">
            <img
              src={require("../../Assets/Icons/playstore.png")}
              alt=""
              style={{
                objectFit: "fill",
                width: "6rem",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(PLAY_STORE_USER, "_blank");
                logEvent(ANALYTICS, 'web_download_click_play_store', {
                  label: 'download',
                  action: "download_app",
                });
              }}
            />
            <img
              src={require("../../Assets/Icons/appstore.png")}
              alt=""
              style={{
                objectFit: "fill",
                width: "6rem",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(APP_STORE_USER, "_blank");
                logEvent(ANALYTICS, 'web_download_click_app_store', {
                  label: 'download',
                  action: "download_app",
                });
              }}
            />
          </Box>
        </Stack>

        <Typography
          variant="little"
          fontSize={["13px", "14px", "17px", "18px", "18px"]}
          alignSelf="center"
          color="black"
        >
          Copyright @{currentYear} by Podian Pvt Ltd.
        </Typography>
      </Box>
    </>
  );
});

export default Footer;

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    segoe: "Segoe UI Variable",
    dances: "Dancing Script",
    navlink: {
      fontSize: "18px",
      fontWeight: "500",
      color: "black",
      "&:hover": {
        color: "#F8AF41",
        transition: "0.3s ease all",
      },
    },
    footerlink: {
      fontSize: "18px",
      color: "rgba(0, 0, 0, 0.70)",
      "&:hover": {
        color: "#F8AF41",
        transition: "0.3s ease all",
      },
    },
    h1: { fontSize: "22px", fontWeight: "500", letterSpacing: "1px" },
    h2: { fontSize: "58px", fontWeight: "800" },
    h3: { fontSize: "54px", fontWeight: "800" },
    h4: { fontSize: "38px", fontWeight: "600" },

    gray: { color: "#4b4d4b", fontSize: "0.980rem", fontWeight: "500" },

    little: { fontSize: "18px", color: "rgba(0, 0, 0, 0.70)" },
    transparent: { color: "#dbdbdb", fontSize: "1rem" },
  },

  palette: {
    primary: {
      main: "#FDE79A",
      second: "#F8AF41",
    },
  },
});

export default theme;

import { useState, memo, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { SlideFromLeft, SlideFromRight } from "../../Utils/Animations";
import { GET_STATISTICS } from "../../Apis/home_api";

const Numbers = memo(() => {
  const [data, setData] = useState({});

  const getData = async () => {
    const res = await GET_STATISTICS();
    if (res && res.s) {
      setData(res.r);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function formateValue(value) {
    if (value >= 1000 && value < 1000000) {
      const result = value / 1000;
      return result % 1 === 0
        ? `${result.toFixed(0)}k`
        : `${result.toFixed(1)}k+`;
    } else if (value >= 1000000 && value < 1000000000) {
      const result = value / 1000000;
      return result % 1 === 0
        ? `${result.toFixed(0)}m`
        : `${result.toFixed(1)}m`;
    } else if (value >= 1000000000 && value < 1000000000000) {
      const result = value / 1000000000;
      return result % 1 === 0
        ? `${result.toFixed(0)}b`
        : `${result.toFixed(1)}b`;
    } else if (value >= 1000000000000 && value < 1000000000000000) {
      const result = value / 1000000000000;
      return result % 1 === 0
        ? `${result.toFixed(0)}t`
        : `${result.toFixed(1)}t`;
    } else if (value >= 1000000000000000 && value < 1000000000000000000) {
      const result = value / 1000000000000;
      return result % 1 === 0
        ? `${result.toFixed(0)}q`
        : `${result.toFixed(1)}q`;
    } else if (value >= 1000000000000000000) {
      const result = value / 1000000000000000000;
      return result % 1 === 0
        ? `${result.toFixed(0)}Q`
        : `${result.toFixed(1)}Q`;
    } else {
      return value.toString();
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        mt: ["0rem", "0rem", "0.5rem", "2.1rem", "4rem"],
        mb: ["3rem", "3rem", "3rem", "4rem", "4rem"],
        justifyContent: "space-between",
        alignItems: "center",
        gap: ["2rem", "8rem", "2.5rem", "7rem", "8rem"],
        px: "2rem",
        py: "1.4rem",
        flexDirection: ["column", "row", "row", "row", "row"],
      }}
    >
      <SlideFromLeft>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={["column", "column", "row", "row", "row"]}
            alignItems={["start", "start", "center", "center", "center"]}
            textAlign={["start", "start", "center", "none", "none"]}
            gap={1.5}
          >
            <Typography
              variant="h2"
              fontSize={["22px", "40px", "58px", "58px", "58px"]}
            >
              {data.shoot_hours ? formateValue(data.shoot_hours) : "12"}
            </Typography>
            <Typography
              fontSize={["16px", "18px", "22px", "22px", "22px"]}
              color="rgba(0, 0, 0, 0.8)"
              whiteSpace="pre-line"
              lineHeight="1.22"
            >
              No. of <br /> Shoot Hours
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            flexDirection={["column", "column", "row", "row", "row"]}
            alignItems={["end", "end", "center", "center", "center"]}
            textAlign={["center", "end", "center", "none", "none"]}
            gap={1.5}
          >
            <Typography
              variant="h2"
              fontSize={["22px", "40px", "58px", "58px", "58px"]}
            >
              {data.booking ? `${formateValue(data.booking)}` : "12K+"}
            </Typography>
            <Typography
              fontSize={["16px", "18px", "22px", "22px", "22px"]}
              color="rgba(0, 0, 0, 0.8)"
              whiteSpace="pre-line"
              lineHeight="1.22"
            >
              No. of <br /> Bookings
            </Typography>
          </Box>
        </Box>
      </SlideFromLeft>

      <SlideFromRight>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={["column", "column", "row", "row", "row"]}
            alignItems={["start", "start", "center", "center", "center"]}
            textAlign={["none", "start", "center", "none", "none"]}
            gap={1.5}
          >
            <Typography
              variant="h2"
              fontSize={["22px", "40px", "58px", "58px", "58px"]}
            >
              {data.photoDelivered ? formateValue(data.photoDelivered) : "500"}
            </Typography>
            <Typography
              fontSize={["16px", "18px", "22px", "22px", "22px"]}
              color="rgba(0, 0, 0, 0.8)"
              whiteSpace="pre-line"
              lineHeight="1.22"
            >
              No. of <br /> Photos delivered
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            flexDirection={["column", "column", "row", "row", "row"]}
            alignItems={["end", "end", "center", "center", "center"]}
            textAlign={["center", "end", "center", "none", "none"]}
            gap={1.5}
          >
            <Typography
              variant="h2"
              fontSize={["22px", "40px", "58px", "58px", "58px"]}
            >
              {data.customers ? formateValue(data.customers) : "60"}
            </Typography>
            <Typography
              fontSize={["16px", "18px", "22px", "22px", "22px"]}
              color="rgba(0, 0, 0, 0.8)"
              whiteSpace="pre-line"
              lineHeight="1.22"
            >
              No. of <br /> Users
            </Typography>
          </Box>
        </Box>
      </SlideFromRight>
    </Box>
  );
});

export default Numbers;

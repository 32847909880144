import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import "./hideScrollbar.css";
import Card from "./Card";
import { Box, CircularProgress } from "@mui/material";
import { TextAnimation } from "../../../Utils/Animations";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelPresentationSharp";

export default function YourComponent({
  data,
  loading,
  setShowFeaturesForMobile,
}) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollPrev();
    } else if (ev.deltaY > 0) {
      apiObj.scrollNext();
    }
  };

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <div
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <TextAnimation>
            <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
              <ScrollMenu onWheel={onWheel} transitionBehavior="smooth">
                {data?.map((e) => (
                  <Card
                    name={e.name}
                    desc={e.description}
                    key={e.id}
                    logo={e.logo}
                    url={e.url}
                    created_at={e.created_at}
                    data={{
                      name: e.name,
                      description: e.description,
                      id: e.id,
                      logo: e.logo,
                      url: e.url,
                    }}
                  />
                ))}
              </ScrollMenu>
            </div>
          </TextAnimation>
        </div>
      )}
    </>
  );
}

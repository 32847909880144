import { Avatar, Box, Stack, Typography } from "@mui/material";
import { API_URL } from "../../../Apis/config";

const Card = ({ name, desc, logo }) => {
  return (
    <Box
      width={["15rem", "18rem", "20rem", "20rem", "20rem"]}
      height={["16rem", "18rem", "20rem", "20rem", "20rem"]}
      bgcolor="linear-gradient(180deg, #FDE89D 0%, rgba(253, 232, 157, 0.30) 100%)"
      borderRadius="25px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      px="0.7rem"
      style={{
        background:
          "linear-gradient(180deg, #FDE89D 0%, rgba(253, 232, 157, 0.30) 100%)",
      }}
    >
      <Stack justifyContent="center" alignItems="center" gap="1rem">
        <Avatar
          src={API_URL.baseUrl + API_URL.getValueLogo + logo}
          sx={{
            width: ["4rem", "4rem", "4.6rem", "4.6rem", "4.6rem"],
            height: ["4rem", "4rem", "4.6rem", "4.6rem", "4.6rem"],
          }}
          alt=""
        />

        <Typography
          variant="h1"
          fontSize={["15px", "16px", "22px", "22px", "22px"]}
        >
          {name ?? ""}
        </Typography>

        <Typography
          color="rgba(0, 0, 0, 0.70)"
          fontSize={["10px", "13px", "16px", "16px", "16px"]}
          textAlign="center"
        >
          {desc ?? ""}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Card;

import { useEffect, useState } from "react";
import { Box, CircularProgress, Skeleton, Stack, Typography } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import { useTheme } from "@emotion/react";
import { GET_GALLERY } from "../../Apis/gallery_api";
// import ButtonView from "../../Components/ButtonView";

const Gallery = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [tabsIsLoading, setTabsIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState([]);
  const preloadImages = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.thumb;
    });
  };

  const getData = async () => {
    const res = await GET_GALLERY();
    if (res && res?.s) {
      setData(res.r);
      if (res?.r.length > 0) {
        setTab(res.r[0]);
        preloadImages(res.r[0].media);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);
  const handleTabClick = (e) => {
    setTabsIsLoading(true);
    setTab(e);
    preloadImages(e.media);
    setTimeout(() => {
      setTabsIsLoading(false);
    }, 1000);
    
  };
  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Stack
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap="2rem"
          mt={["1rem", "1rem", "3.2rem", "3.2rem", "3.2rem"]}
          pb={["2rem", "2rem", "0.3rem", "0.3rem", "0.3rem"]}
        >
          <Typography
            variant="h4"
            fontSize={["28px", "30px", "38px", "38px", "38px"]}
            alignSelf="center"
          >
            Gallery
          </Typography>

          <Box
            sx={{
              width: ["95%", "95%", "70%", "70%", "70%"],
              minHeight: [0, 0, 800, 829, 829],
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1.5rem",
              py: "0.7rem",
            }}
          >
            <Stack
              direction="row"
              width="98%"
              justifyContent="space-between"
              alignItems="center"
              // overflowX="scroll"
              className="galleryCategoryList"
              sx={{
                whiteSpace: "nowrap",
                overflowX: "scroll",
                gridGap: "2.5rem",
                paddingBottom:'20px'
              }}
            >
              {data &&
                data?.map((e, i) => (
                  <button
                    key={i}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      flexShrink: 0,
                    }}
                    onClick={() => handleTabClick(e)}
                  >
                    <Typography
                      color={
                        tab?.name === e?.name
                          ? theme.palette.primary.second
                          : "#4b4d4b"
                      }
                      fontWeight="500"
                      fontSize={["13px", "15px", "16px", "18px", "18px"]}
                    >
                      {e.name}
                    </Typography>
                  </button>
                ))}
            </Stack>

            {tabsIsLoading ? (
              <Masonry columns={3} spacing={2}>
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    // width="100%"
                    height={200}
                  />
                ))}
              </Masonry>
            ) : (
              <Masonry columns={3} spacing={2}>
                {tab &&
                  tab?.media &&
                  tab?.media.map((item, index) => (
                    <div key={index} style={{ boxShadow: 10 }}>
                      <img
                        srcSet={`${item?.thumb}?w=162&auto=format&dpr=2 2x`}
                        src={`${item?.thumb}?w=162&auto=format`}
                        alt={item?.name}
                        loading="lazy"
                        style={{
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                          display: "block",
                          width: "100%",
                        }}
                      />
                    </div>
                  ))}
              </Masonry>
            )}
          </Box>

          {/* <ButtonView label="View More" onClick={() => {}} /> */}
        </Stack>
      )}
    </>
  );
};

export default Gallery;
